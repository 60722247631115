import { Divider } from "../components/Divider"
import { ServiceDropdown } from '../components/ServiceDropdown';
import { Faq } from "../services/Faq";
import { Insurance } from "../services/Insurance";

export const PatientsInfo = () => {
    
    return (
        <div className="flex justify-center bg-gradient-to-tr from-[#cc98b4] to-[#ebd8e1] min-h-[100vh] font-['Raleway']">
            <div className="flex flex-col md:w-[60%] w-screen bg-[#fffbfb] text-gray-700">
                <div className="flex flex-col md:mx-10 lg:mx-20 mx-5 py-10">
                    <text className="text-xl font-bold">Patients Info</text>
                    <Divider className="w-[120px]"></Divider>
                    <div className="flex flex-col space-y-7 py-7">
                        <ServiceDropdown title={"Insurance Direct Billing for Your Convenience"} message={<Insurance></Insurance>}></ServiceDropdown>
                        <ServiceDropdown title={"FAQ"} message={<Faq></Faq>}></ServiceDropdown>
                    </div>

                    <div className="flex flex-row items-center space-x-2">
                        <text className="text-lg font-semibold">Fee Schedule</text>
                        <div className ="flex-grow border-b-[1px] border-gray-400"></div>
                    </div>

                    <div className="md:px-[20%] pt-7 pb-7">
                        <div className="border rounded-lg">
                            <div className="flex flex-col divide-y text-lg">
                                <div className="text-center py-5 font-semibold">
                                    <text> Massage therapy - Swedish, deep tissue, sport, and pregnancy</text>
                                </div>
                                <div className="grid grid-cols-2 divide-x">
                                    <div className="text-center py-2"><text>30 min</text></div>
                                    <div className="text-center py-2"><text>$75 (HST Included)</text></div>
                                </div>
                                <div className="grid grid-cols-2 divide-x">
                                    <div className="text-center py-2"><text>45 min</text></div>
                                    <div className="text-center py-2"><text>$98 (HST Included)</text></div>
                                </div>
                                <div className="grid grid-cols-2 divide-x">
                                    <div className="text-center py-2"><text>60 min</text></div>
                                    <div className="text-center py-2"><text>$119 (HST Included)</text></div>
                                </div>
                                <div className="grid grid-cols-2 divide-x">
                                    <div className="text-center py-2"><text>90 min</text></div>
                                    <div className="text-center py-2"><text>$158 (HST Included)</text></div>
                                </div>
                                <div className="text-center py-5 font-semibold">
                                    <text>Add to any massage therapy treatments:</text>
                                </div>
                                <div className="grid grid-cols-2 divide-x">
                                    <div className="text-center py-2"><text>Hot Stone</text></div>
                                    <div className="text-center py-2"><text>$10</text></div>
                                </div>
                                <div className="grid grid-cols-2 divide-x">
                                    <div className="text-center py-2"><text>Foot Scrub</text></div>
                                    <div className="text-center py-2"><text>$5</text></div>
                                </div>
                                <div className="grid grid-cols-2 divide-x">
                                    <div className="text-center py-2"><text>Trigger Point Therapy</text></div>
                                    <div className="text-center py-2"><text>$10</text></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row items-center space-x-2">
                        <text className="text-lg font-semibold">Cancellation Policy</text>
                        <div className ="flex-grow border-b-[1px] border-gray-400"></div>
                    </div>

                    <div className="text-md py-3 space-y-2">
                        <p>If you need to make changes to your schedule appointments, we expect 24 hour notice.
                        If you are late for your appointment you will be charged the amount of the requested length and treated for the remaining.</p>
                    </div>

                    <text className="text-lg font-semibold border rounded-md text-center">Your appointment is your responsibility.</text>

                    <div className="flex flex-row space-x-3 pt-7">
                        <div className="border-l border-[2px] border-[#a44474]"></div>
                        <div className="pt-2">
                            <p className="text-md">We accept Credit, Debit, or cash, we also provide direct billing from your insurance company.</p>
                            <p className="text-md">In the event that Extended Health Coverage benefits fail to pay for services rendered, you will become fully responsible for the payment.</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
